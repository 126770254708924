html.webp {
  .ribbon {
    &--large {
      @include backgroundimage('ribbon-bg.webp');
      background-repeat: repeat-x;

      &:before {
        @include backgroundimage('ribbon-left.webp');
      }

      &:after {
        @include backgroundimage('ribbon-right.webp');
      }
    }

    &--small {
      @include backgroundimage('ribbon-small-bg.webp');
      background-repeat: repeat-x;

      &:before {
        @include backgroundimage('ribbon-small-left.webp');
      }

      &:after {
        @include backgroundimage('ribbon-small-right.webp');
      }
    }
  }
}

$edgeHang: 10px;
$ribbonPadding: 8px;
.ribbon {
  position: absolute;
  top: 10px;
  color: #ffffff;
  background-color: transparent;
  @include fontsize(22);
  line-height: 1;
  z-index: 1;

  // Large ribbon styling
  &--large {
    @include backgroundimage('ribbon-bg.png');
    background-repeat: repeat-x;
    min-height: 89px;
    left: 28px;
    padding-top: 25px;
    @include fontbl();
    @include fontsize(32);
    line-height: 1;

    &:before {
      @include backgroundimage('ribbon-left.png');
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -38px;
      width: 38px;
      height: 133px;
    }

    &:after {
      @include backgroundimage('ribbon-right.png');
      content: '';
      position: absolute;
      display: block;
      width: 39px;
      height: 90px;
      right: -39px;
      top: 0;
    }

    h2 {
      color: #ffffff;
      font-size: 38px;
    }
  }

  // Small ribbon styling
  &--small {
    @include backgroundimage('ribbon-small-bg.png');
    background-repeat: repeat-x;
    min-height: 44px;
    left: 7px;
    @include fonta();
    padding-top: 10px;

    &:before {
      @include backgroundimage('ribbon-small-left.png');
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -17px;
      width: 17px;
      height: 70px;
    }

    &:after {
      @include backgroundimage('ribbon-small-right.png');
      content: '';
      position: absolute;
      display: block;
      width: 18px;
      height: 44px;
      right: -18px;
      top: 0;
    }

    // Unlock modifier
    &--unlock {
      color: $color_pink;
      @include backgroundimage('new-ribbon-bg.png');
      background-repeat: repeat-x;
      left: 11px;
      z-index: 100;

      &:before {
        @include backgroundimage('new-ribbon-left.png');
      }

      &:after {
        @include backgroundimage('new-ribbon-right.png');
      }
    }
  }
}

[dir='rtl'] {
  .ribbon {
    left: auto;
    right: -$edgeHang;
    @include transform(scale(-1, 1));

    &--large {
      left: auto;
      right: 28px;
    }

    // Small ribbon styling
    &--small {
      left: auto;
      right: -8px;
    }

    // Small ribbon styling
    &--small--unlock {
      left: auto;
      right: 11px;
    }

    h2,
    span {
      display: block;
      @include transform(scale(-1, 1));
    }
  }
}

@media (max-width: 767px) {
  $edgeHang: 8px;
  $ribbonPadding: 5px;
  .ribbon {
    line-height: 1;
    &--large {
      padding-top: 25px;
      @include fontsize(22);
      line-height: 1;
      &:after {
        border-width: 32px 30px 0 0;
      }
    }

    h2 {
      @include fontsize(28);
    }
  }
  [dir='rtl'] {
    .ribbon {
      left: auto;

      &-large {
        right: -$edgeHang;
      }
    }
  }
}
