.b_blue {
  background-color: $color_blue;
  color: #ffffff;
}

.b_yellow {
  background-color: $color_yellow;
  color: $color_purple;
}

.b_purple {
  background-color: $color_purple;
  color: #ffffff;
}

.b_pink {
  background-color: $color_pink;
  color: #ffffff;
}

.b_white {
  background-color: #ffffff;
}
