.like_views_container {
  float: right;
  margin-left: 10px;
  // direction: rtl;

  &--hidden {
    display: none;
  }
}

.like_views_container {
  // direction: ltr;
  background-color: transparent;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  float: none;
  display: flex;
  align-items: center;

  .emojis {
    align-items: center;
  }

  .view_counter {
    display: flex;
    align-items: flex-end;
    width: 240px;

    .count,
    .icon_container {
      padding: 0 10px 0 0;
      @include fontsize(30);
      color: $color_purple;

      width: auto;
      height: auto;
    }

    .count {
      @include fontsize(34);
      @include fontb;
    }
  }
}
