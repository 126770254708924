.hero_section{
	margin-top: 20px;

	> .wrapper{
		border-radius: 20px;
	}

	.hero_image{
		position: relative;

		img {
			border-radius: 10px;
		}
		.buttons{
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			padding: $padding;
			.wrapper{
				width: 100%;
			}
		}
	}

	.caption {
		p {
			color: $color_purple;
		}
	}

	.button {

	}
}


@media (max-width: 767px){
	.hero_section{
		.hero_image{
			.buttons{
				position: relative;
				padding: math.div($padding, 2) 0;
			}
		}
	}
}
