/*
This file is used to import ONLY the -dir.scss files.

NOTE: Do NOT include critcial css style into this file

Critical CSS styling should be included inside js/main.js
to allow Webpack to chunk it out then inline the styling
*/

// https://sass-lang.com/documentation/modules/math
@use "sass:math";

/* Abstract file import */
@import 'abstracts/__abstracts-dir';

/* Vendor file import */
@import 'vendor/__vendor-dir';

/* Base file import */
@import 'base/__base-dir';

/* Components file import */
@import 'components/__components-dir';

/* Layouts file import */
@import 'layouts/__layouts-dir';

/* Pages file import
@import 'pages/__pages-dir'; */

/*
 Ste muckarounds
 Added :
 components/category-items
 layouts/four-up
 */
.carousel_row_placeholder {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;

  .content_item {
    height: 220px; // this is so wrong and not responsive - hope you can sort this Ash :)
  }

  .placeholder-loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .icon {
      width: 40px;
      height: 40px;
      text-align: center;
    }
  }
}
.content_block_placeholder {
  height: 600px; // again this is so wrong and not responsive - hope you can sort this Ash :)
  display: flex;
  align-items: center;
  justify-content: center;
  .icon_container {
    width: 100px;
    height: 100px;
  }
}
