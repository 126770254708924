.featured {
  .wrapper {
    border-radius: 40px;
    padding: $padding * 5.5 $padding $padding $padding;
  }
  .featured_wrapper {
    text-align: left;
    font-size: 0;
    direction: ltr;
  }
  .featured_item {
    position: relative;
    width: 32%;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
    display: inline-block;
    vertical-align: middle;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
    .content_item {
      //max-width: 556px;
    }
  }
  &.featured-item-3 {
    .featured_item {
      margin-left: 2%;
    }
  }
  &.featured-size-4,
  &.featured-size-2 {
    .featured_item {
      width: 49.25%;

      &:nth-child(3n + 1) {
        margin-left: 1.5%;
      }
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
  }
}

// MPU Layout
.featured.featured-size-3.featured--mpuLayout {
  .featured_item {
    position: relative;
    width: 48%;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
    display: inline-block;
    vertical-align: middle;

    @media (min-width: 1024px) {
      width: 31%;
    }

    &.mpu {
      @media (max-width: 1024px) {
        width: 300px;
        display: block;
        margin: auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .featured {
    .wrapper {
      padding: $padding * 5.5 math.div($padding, 2) math.div($padding, 2) math.div($padding, 2);
    }
    > .wrapper {
      > .ribbon {
        top: 10px;
      }
    }

    .featured_item {
      width: 100% !important;
      max-width: none !important;
      margin-left: 0 !important;
      margin-bottom: 10px !important;
    }
  }
}
