/* Sort amends : using react-dropdown with different dom structure
------------------------------------------------------------------------------- */
.sort {
  float: left;
  width: 100%;
  margin-bottom: 3%;
  cursor: pointer;
}

.sort {
  .Dropdown-placeholder {
    border-radius: 24px;
    display: block;
    float: left;
    width: 100%;
    font-size: 2.3rem;
    background-color: #43b8e7;
    @include backgroundimage('icons/arrow_down.svg');
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 12%;
    color: #fff;
    padding: 10px 15px;
    text-transform: none;
    text-decoration: none;
    background-color: $color_purple;
  }
  &--videos .Dropdown-placeholder {
    background-color: $color_pink_offset;
  }
  &--games .Dropdown-placeholder {
    background-color: $color_blue;
  }
}

html[dir='rtl'] .sort .Dropdown-placeholder {
  background-position: 5% center;
  float: right;
}

.sort {
  .Dropdown-menu {
    border-radius: 24px;
    display: none;
    float: left;
    width: 100%;
    padding: 2%;
    margin-top: 2px;
    background-color: $color_purple;
    max-height: 300px;
    overflow-y: auto;
    border: {
      color: white;
      style: solid;
      width: 4px;
    }
  }
  &--videos .Dropdown-menu {
    background-color: $color_pink_offset;
  }
  &--games .Dropdown-menu {
    background-color: $color_blue;
  }
}

.sort .Dropdown-menu::-webkit-scrollbar {
  width: 12px;
}

.sort .Dropdown-menu::-webkit-scrollbar-track {
  background: $color_purple;
}

.sort .Dropdown-menu::-webkit-scrollbar-thumb {
  background: lighten($color_purple, 20%);
}

.sort .Dropdown-menu .Dropdown-option {
  cursor: pointer;
}

.sort.is-open .Dropdown-menu {
  display: block;
}

.sort.is-open .Dropdown-placeholder {
  @include backgroundimage('icons/arrow_up.svg');
}

.sort .Dropdown-option {
  background: none !important;
  padding: 1%;
}

.sort .Dropdown-option:focus,
.sort .Dropdown-option:hover {
  color: $color_yellow;
}
.sort .Dropdown-option.is-selected {
  color: #000000;
}

/* Media queries
------------------------------------------------------------------------------- */

@media all and (min-width: 480px) {
  .sort {
    position: relative;
    float: right;
    width: 200px;
  }

  html[dir='rtl'] .sort {
    float: left;
  }

  .sort .Dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 40;
    padding: 10px;
  }

  .sort .Dropdown-menu .Dropdown-option {
    font-size: 2.8rem;
    padding: 5px 0;
  }
}

@media all and (min-width: 768px) {
  .sort {
    float: none;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  html[dir='rtl'] .sort {
    right: auto;
    left: 20px;
  }
}
