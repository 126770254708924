.cookie_popup {
  width: 100%;
  z-index: 999999;
  position: fixed;
  bottom: 0;
  padding: 1%;
  background-color: $color_purple;
  color: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.2);
  .inner {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
  }
  .text {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 5%;
  }
  .buttons {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
  a {
    background-color: $color_blue;
    color: #000;
    border-radius: 10px;
    padding: 4px 7px;
    font-size: 2.2rem;
    line-height: 1.1;
    display: inline-block;
    margin: 2px 4px;
  }
  h5 {
    font-size: 2.4rem;
  }

  p {
    font-size: 1.8rem;
  }
}

.no-touchevents {
  .cookie_popup {
    a:hover {
      background-color: $color_pink;
    }
  }
}

[dir='rtl'] {
  .cookie_popup {
    a {
      direction: rtl;
    }
  }
}
