.more_button {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 280px;

  background-color: white;
  border: {
    style: solid;

    top-width: 3px;
    right-width: 10px;
    bottom-width: 10px;
    left-width: 3px;

    top-left-radius: 23px;
    top-right-radius: 32px;
    bottom-right-radius: 30px;
    bottom-left-radius: 32px;
  }
}

.more_button__inner {
  background: {
    position: top right;
    repeat: no-repeat;
    size: 100% auto;
  }
  border-radius: 20px;
  background-color: $color_purple;
  @include backgroundimage('bg_pattern_purple_small.png');
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #fff;
  padding: 10px 20px;
  text-transform: none;
  text-decoration: none;
  @include fontsize(45);
  cursor: pointer;
}

.more_button--videos {
  .more_button__inner {
    background-color: $color_pink_offset;
    @include backgroundimage('bg_pattern_pink_small.png');
  }

  &:hover {
    .more_button__inner {
      background-color: $color_blue;
      background-image: none !important;
    }
  }
}

.more_button--games,
.more_button--web_game_category {
  .more_button__inner {
    background-color: $color_blue;
    @include backgroundimage('bg_pattern_blue_small.png');
  }

  &:hover {
    .more_button__inner {
      background-color: $color_pink_offset;
      background-image: none !important;
    }
  }
}

.more_button span {
  display: block;
  margin: 0;
}

.more_button .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  @include translatecentery();
  @include fontsize(90);
  font-family: Arial, sans-serif;
  -webkit-transition: -webkit-transform 0.3s $bounce;
  transition: transform 0.3s $bounce;
}

html[dir='rtl'] {
  .more_button span {
    //width : 80%;
    margin-right: 20%;
  }

  .more_button .icon {
    margin-right: 0%;
  }
}

html[dir='rtl'] .more_button .icon {
  float: left;
}
.no-touchevents {
  .more_button:hover {
    background-color: $color_pink;
    background-image: none !important;

    .icon {
      @include translatecentery(rotate(-20deg));
    }
  }
}
.more_button:active,
.more_button:focus {
  background-color: $color_pink;
}
