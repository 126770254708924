.flash-message{
	position: relative;
  padding: 100px 20px;
  background-color: rgba(0, 0, 0, 0.1);
	border-radius: 20px;

	&__title{
		color: $color_purple;
		text-align: center;
		text-transform: none;
		@include fontsize(50);
		@include fontb();
		padding: $sectionMargin;
		display: block;
		margin: 0 auto;
	}

	// buttons
	&__buttons {
		display: flex;
		justify-content: center;
	}

	&__button span {
		border-radius: 20px;
		margin: 0 5px;
	}
}

@media(max-width: 767px){
	.flash-message{
		&__title{
			@include fontsize(30);
			padding: math.div($sectionMargin, 2);
		}

		// buttons
		&__buttons {
			display: block;
		}

		&__button span {
			width: 100%;
			margin: 2.5px 0;
		}
	}
}
