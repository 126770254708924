.player {
  position: relative;
  margin-top: 0;
}

.player iframe,
.player object,
.player embed,
.player video {
  margin: 0 auto;
  display: block;
  z-index: 100;
}

.player .bitmovin_player {
  width: 60%;
  margin: 0 20%;
  iframe,
  object,
  embed,
  video {
    z-index: 0;
  }
}

.player .video_wrapper {
  max-width: 800px;
  margin: 0 auto;
}

@media all and (min-width: 768px) {
  .player .article {
    float: none;
  }
}
