.block-list {
  margin-top: 0;
  .wrapper {
    &--ribbon {
      padding-top: 110px;
    }

    > h2 {
      @include fontbl();
      @include fontsize(48);
      color: $color_purple;
      line-height: 1;
      min-height: 4.8rem;
      margin-bottom: $padding;
    }
  }

  &.block-list-filter {
    margin-top: $sectionMargin;
    .content_item {
      margin-bottom: $padding;
    }
  }

  &.margin_top {
    margin-top: $sectionMargin;
  }
}

@media (max-width: 767px) {
  .block-list {
    .wrapper > h2 {
      @include fontsize(33);
      margin: {
        top: 0;
        bottom: math.div($padding, 2);
      }
      min-height: auto;
    }

    &.block-list-filter {
      margin-top: math.div($sectionMargin, 2);
      .content_item {
        margin-bottom: math.div($padding, 2);
      }
    }
    &.margin_top {
      margin-top: math.div($sectionMargin, 2);
    }
  }
}
