// Used by the Feedblocks

@import 'carousel_widget';

.carousel_widget.swiper {
  .carousel_item {
    //width: 30.33%;
    //width: -webkit-calc(33.33% - 2%);
    //width: calc(33.33% - 2%);
    margin-right: 2%;
  }
  .nav_button {
    cursor: pointer;
    height: 74px;
    width: 74px;
    position: absolute;
    top: 32%;
    @include translatecentery();
    background: $color_purple;
    background-size: 35% auto;
    background-position: center;
    background-repeat: no-repeat;
    &.nav_left {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      left: 14px; /*Because of carousel blocks overflow offset to fix "new" label */
      @include backgroundimage('icons/arrow_left.svg');
      @include transformorigin(0% 50%);
      @include carouselArrowsInActive(60deg);
    }
    &.nav_right {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      right: 1px;
      @include backgroundimage('icons/arrow_right.svg');
      @include transformorigin(100% 50%);
      @include carouselArrowsInActive(90deg);
    }
    &.disabled {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
}
html[dir='rtl'] {
  .carousel_widget.swiper {
    .nav_button {
      &.nav_left {
        left: 0;
      }
      &.nav_right {
        right: 15px;
      }
    }
  }
}
html[dir='rtl'] .carousel_widget.swiper .carousel_item {
  //margin-right: 1.6%;
  margin-right: 0;
  margin-left: 1.6%;
}

.carousel_widget.swiper {
  .carousel_item {
    // display: inline-block;
    vertical-align: top;
  }
  .nav_button:hover {
    background-size: 45% auto;
  }
  &:hover .nav_button {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
    &.show.disabled {
      display: none;
    }
  }
  .nav_button.show {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
}
//Show arrows on mobile touch
.show_all_carousel_nav_buttons section .carousel_widget.swiper .nav_button {
  @include carouselArrowsActive();
}
section:active .carousel_widget.swiper .nav_button {
  &.disabled {
    display: none;
  }
  &.swiper-button-disabled {
    display: none;
  }
  &.show.disabled {
    display: none;
  }
}
@media (max-width: 767px) {
  .carousel_widget.swiper:not(.app-screenshots__list) {
    .swiper-slide {
      margin-bottom: 20px;
    }
  }
}
