.scroll_top_button {
  position: relative;
  display: none;
  text-align: center;
  .wrapper {
    padding: 0;
  }
  a {
    background-color: $color_blue;
    background: {
      position: top right;
      repeat: no-repeat;
      size: 100% auto;
    }
    @include backgroundimage('bg_pattern_blue_small.png');
    border-radius: 20px;
    position: relative;
    display: inline-block;
    margin: 0 auto;
    @include fontsize(36);
    @include fontbl();
    text-transform: none;
    padding: math.div($padding, 2);
  }
  .icon {
    height: math.div($iconHeight, 2);
    display: inline-block;
    vertical-align: middle;
    margin-top: -9px;
    margin-left: 10px;
    @include animation('bounceInUp 1s infinite');
    @include transform(translate(0, 5px));
  }
}

@include keyframes(bounceInUp) {
  50% {
    @include transform(translate(0, -5px));
  }
}

@media (max-width: 767px) {
  .scroll_top_button {
    display: block;
  }
}

// Desktop version of scrollto top
.scrollTop {
  background: no-repeat url(../images/chevron_white.svg) 50%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: fixed;
  width: 64px;
  height: 64px;
  right: 40px;
  bottom: 20px;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  -webkit-animation: scrollFadeIn 0.3s;
  animation: scrollFadeIn 0.3s;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: ease-in-out 0.2s;
  transition: 0.2s ease-in-out;
  opacity: 1;
  display: -ms-flexbox;
  display: flex;
  background-color: #000;
  outline: none;
  border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  .scrollTop {
    right: 20px;
  }
}

.scrollTop:hover {
  opacity: 1;
}

@-webkit-keyframes scrollFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes scrollFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html[dir='rtl'] {
  .scrollTop {
    right: auto;
    left: 40px;
  }

  @media only screen and (max-width: 768px) {
    .scrollTop {
      left: 20px;
    }
  }
}
