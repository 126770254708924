.content_hubs-header{
	position: relative;
	&__banner{
		width: 100%;
		display: block;
		img{
			border-radius: 20px;
			width: 100%;
			display: block;
		}
		&--desktop{
			display: block;
		}
		&--mobile{
			display: none;
		}
	}

	//margin-bottom: math.div($sectionMargin, 2);
}

@media (max-width: 767px){
	.content_hubs-header{
		&__banner{
			&--desktop{
				display: none;
			}
			&--mobile{
				display: block;
			}
		}
	}
}
