.mpu a > img {
  width: auto;
}

.adtech {
  display: none;
}

.floor_ad {
  position: relative;
  z-index: 1000;
}

.mpu {
  text-align: center;
  z-index: 0;

  .mpu_inner {
    display: inline-block;
    padding: 3px;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    @include fontsize(13);
    @include fontb();
    text-transform: none;

    @media screen and (min-width: 767px) {
      max-width: 100%;

      img,
      iframe,
      div {
        max-width: 100%;
      }
    }

    .mpu_inner .adtech {
      display: inline-block;
    }
    .mpu_label {
      margin-top: 6px;
    }
  }
}
