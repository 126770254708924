.emoji__target {
  cursor: pointer;
}

.social-container {
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
}

.emojis {
  display: flex;
  margin-left: auto;
}

.emojis .total-likes {
  margin-right: 10px;
}

.emojis .emojis__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emojis .emoji {
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    margin: 0 10px;
  }

  img {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) {
    img {
      width: 50px;
    }
  }
  @media (min-width: 850px) {
    img {
      width: 56px;
    }
  }
  @media (min-width: 1024px) {
    img {
      width: 75px;
    }
  }

  .count {
    $fontsize: 20;
    $padding: 5;

    background-color: $color_purple;

    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    padding: {
      top: $padding + px;
      right: ($padding * 3) + px;
      bottom: $padding + px;
      left: ($padding * 4) + px;
    }

    height: $fontsize + ($padding * 2) + px;
    position: relative;
    transform: translateX(-20px);
    z-index: 1;

    @include fontb;
    @include fontsize($fontsize);
  }

  &--total {
    display: flex;

    @media (min-width: 768px) {
      display: none;
    }
    .count {
      transform: translateX(-15px);
    }
  }
}

.emojis {
  .desktop-view {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1024px) {
      margin-top: 4px;
    }
  }

  .mobile-view {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }
}

.emojis .emoji--total,
.emojis .emoji--close {
  cursor: pointer;
}

.emojis .emoji--close {
  position: absolute;
  top: 2px;
  width: 60px;
  display: none;
  z-index: 1002;

  @media (min-width: 768px) {
    display: none !important;
  }
}

.emojis.active .emoji--close {
  display: block;
}

html[dir='rtl'] {
  .emojis {
    margin-right: auto;
    margin-left: unset;

    @media only screen and (min-width: 768px) {
      width: 65%;
    }
  }
  .emojis .total-likes {
    margin-left: -20px;
  }
  .emojis .emoji--close,
  .emojis .emoji--total {
    direction: ltr;
  }
}

// ***** Shows Page styling
body.shows {
  &.active-emojis:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.75);
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .featured_header .featured_header_item--featured-video .content_item_inner .label {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .details {
      order: 1;
    }

    .social-container {
      order: 2;
      @media (max-width: 768px) {
        order: 1;
        flex-direction: column;
        float: right;
        width: 150px;
        margin-top: 5px;
        padding: {
          top: 0;
          bottom: 10px;
          left: 10px;
        }
      }
    }
  }

  .featured_header .featured_header_item--featured-video .content_item_inner .label h2 {
    @media (max-width: 768px) {
      @include fontsize(34);
      margin-bottom: 20px;
    }
  }

  // Emoji styling for video pages
  .emojis {
    @media (max-width: 768px) {
      position: relative;
      right: -20px;
    }
  }

  .emojis {
    &.active {
      .emojis__container {
        display: flex;
      }
    }

    .emojis__container {
      flex-wrap: wrap;
      flex-direction: row;

      @media (max-width: 768px) {
        display: none;
        position: absolute;
        top: 50%;
        z-index: 1001;

        transform: translateY(-50%);
      }

      .emoji {
        margin-bottom: 10px;

        .count {
          @media (max-width: 768px) {
            border-radius: 25px;
            padding: 5px 17px;
            transform: translateX(0) translateY(-6px);
          }
        }

        @media (max-width: 768px) {
          position: relative;
          flex-direction: column;
          justify-content: center;

          &:nth-child(1) {
            right: 20px;
            top: 130px;
          }

          &:nth-child(2) {
            right: 100px;
            top: 50px;
          }

          &:nth-child(3) {
            right: 180px;
            top: 10px;
          }

          &:nth-child(4) {
            right: 210px;
            top: 10px;
          }

          &:nth-child(5) {
            right: 180px;
            top: 10px;
          }

          &:nth-child(6) {
            right: 100px;
            top: -30px;
          }

          &:nth-child(7) {
            right: 20px;
            top: -100px;
          }
        }

        @media (min-width: 768px) {
          flex-basis: calc(33.3% - 30px);
        }
      }
    }
  }

  .like_views_container {
    margin-left: 0;

    @media only screen and (min-width: 768px) {
      width: 25%;
    }

    @media only screen and (min-width: 1120px) {
      width: 35%;
    }
  }

  .view_counter {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;

    @media (min-width: 768px) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    @media (min-width: 940px) {
      align-items: flex-end;
      flex-direction: row;
    }

    .count,
    .icon_container {
      padding: 0 10px 0 0;
      @include fontsize(34);
      color: $color_purple;

      width: auto;
      height: auto;

      margin-bottom: 20px;

      @media (min-width: 768px) {
        @include fontsize(30);
        margin-bottom: 0px;
      }
    }

    .count {
      @include fontsize(34);
      @include fontb;
    }
  }

  .player-details {
    display: flex;
    flex-direction: column;
  }
}

html[dir='rtl'] {
  body.shows {
    .emojis__container .emoji img {
      order: 2;
    }
    .view_counter .count,
    .view_counter .count {
      align-self: flex-end;
    }

    .social-container {
      float: left !important;
    }
    .emojis .emoji--close {
      top: 7px;
      left: -12px;
    }

    .emojis .emojis__container .emoji {
      @media (max-width: 768px) {
        &:nth-child(1) {
          left: 20px;
          right: auto;
        }

        &:nth-child(2) {
          left: 100px;
          right: auto;
        }

        &:nth-child(3) {
          left: 180px;
          right: auto;
        }

        &:nth-child(4) {
          left: 210px;
          right: auto;
        }

        &:nth-child(5) {
          left: 180px;
          right: auto;
        }

        &:nth-child(6) {
          left: 100px;
          right: auto;
        }

        &:nth-child(7) {
          left: 20px;
          right: auto;
        }
      }
    }
  }
}
