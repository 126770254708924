html.webp {
  .button {
    &.games_holder {
      .button_inner {
        @include backgroundimage('bg_pattern_blue_small.webp');
      }
    }

    &.videos_holder {
      .button_inner {
        @include backgroundimage('bg_pattern_pink_small.webp');
      }
    }
  }

  .buttons--footer-view .button__games .button_inner {
    @include backgroundimage('bg_pattern_blue_small.webp');
  }

  .buttons--footer-view .button__videos .button_inner {
    @include backgroundimage('bg_pattern_pink_small.webp');
  }
}

.button {
  position: relative;
  display: inline-block;
  @include fontsize(48);
  line-height: 1;
  @include fontb();
  text-transform: none;
  text-decoration: none;
  cursor: pointer;

  .button_inner {
    position: relative;
    display: block;
    padding: 10px;
    padding-right: 60px;
    background-color: $color_blue;
    color: #ffffff;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.multi_line {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }
  }

  .button_icon {
    position: absolute;
    top: 50%;
    right: 10px;
    height: 60px;
    @include translatecentery();
    -webkit-transition: -webkit-transform 0.3s $superbounce;
    transition: transform 0.3s $superbounce;
  }

  &--pink {
    .button_inner {
      background-color: $color_pink;
    }
  }

  &--darkpurple {
    .button_inner {
      background-color: darken($color_purple, 15%);
    }
  }
  &.button--small {
    @include fontsize(18);
    line-height: 1;
    .button_inner {
      padding-left: 34px;
      padding-right: 14px;
      &:before {
        width: 27px;
      }
    }
  }
  &.active {
    .button_inner {
      background-color: $color_pink;
    }
  }

  &.default-cursor {
    cursor: default;
    .button_icon {
      @include translatecentery(!important);
    }
  }

  &.disabled {
    opacity: 0.2;
    cursor: default;
  }

  &--align-top {
    vertical-align: top;
  }

  &.games_holder {
    background-color: white;
    border: {
      style: solid;

      top-width: 3px;
      right-width: 10px;
      bottom-width: 10px;
      left-width: 3px;

      top-left-radius: 23px;
      top-right-radius: 32px;
      bottom-right-radius: 30px;
      bottom-left-radius: 32px;
    }
    margin: {
      right: 1%;
      left: 1%;
    }

    .button_inner {
      background-color: $color_blue;
      @include backgroundimage('bg_pattern_blue_small.png');

      background: {
        position: top right;
        repeat: no-repeat;
        size: 100% auto;
      }
      border-radius: 20px;
    }

    &.active {
      .button_inner {
        background-color: $color_pink;
        background-image: none;
      }
    }
  }

  &.videos_holder {
    background-color: white;
    border: {
      style: solid;

      top-width: 3px;
      right-width: 10px;
      bottom-width: 10px;
      left-width: 3px;

      top-left-radius: 23px;
      top-right-radius: 32px;
      bottom-right-radius: 30px;
      bottom-left-radius: 32px;
    }
    margin: {
      right: 1%;
      left: 1%;
    }
    .button_inner {
      background-color: $color_pink_offset;
      @include backgroundimage('bg_pattern_pink_small.png');

      background: {
        position: top right;
        repeat: no-repeat;
        size: 100% auto;
      }
      border-radius: 20px;
    }

    &.active {
      .button_inner {
        background-color: $color_blue;
        background-image: none;
      }
    }
  }

  &.comics_holder,
  &.quizzes_holder {
    background-color: white;
    border: {
      style: solid;

      top-width: 3px;
      right-width: 10px;
      bottom-width: 10px;
      left-width: 3px;

      top-left-radius: 23px;
      top-right-radius: 32px;
      bottom-right-radius: 30px;
      bottom-left-radius: 32px;
    }
    margin: {
      right: 1%;
      left: 1%;
    }
    .button_inner {
      background-color: $color_purple;
      @include backgroundimage('bg_pattern_purple_small.png');

      background: {
        position: top right;
        repeat: no-repeat;
        size: 100% auto;
      }
      border-radius: 20px;
    }
    &.active {
      .button_inner {
        background-color: $color_blue;
        background-image: none;
      }
    }
  }
}

[dir='rtl'] {
  .button {
    text-align: right;
    direction: rtl;
    .button_inner {
      padding-right: 10px;
    }
    .button_icon {
      right: auto;
      left: 10px;
    }

    &.button--small {
      .button_inner {
        padding-left: 14px;
        padding-right: 34px;
      }
    }
  }

  .hero_image .button .button_inner {
    padding-bottom: 20px;
  }
}

.buttons {
  font-size: 0;
  direction: ltr;
  /* text-align: center; */
  .wrapper {
    padding: 0;
  }
  .button {
    width: 48%;
    margin-bottom: 10px;
    /* text-align: left; */
  }

  &--footer-view {
    .button {
      background-color: white;
      border: {
        style: solid;

        top-width: 3px;
        right-width: 10px;
        bottom-width: 10px;
        left-width: 3px;

        top-left-radius: 23px;
        top-right-radius: 32px;
        bottom-right-radius: 30px;
        bottom-left-radius: 32px;
      }
      &:nth-child(odd) {
        margin-right: 10px;
      }
      &:nth-child(even) {
        margin-left: 10px;
      }
    }
    .button_inner {
      background: {
        position: top right;
        repeat: no-repeat;
        size: 100% auto;
      }
      border-radius: 20px;
    }
    .button__games {
      .button_inner {
        background-color: $color_blue;
        @include backgroundimage('bg_pattern_blue_small.png');
      }
    }

    .button__videos {
      .button_inner {
        background-color: $color_pink_offset;
        @include backgroundimage('bg_pattern_pink_small.png');
      }
    }

    .button__comics {
      .button_inner {
        background-color: $color_purple;
        @include backgroundimage('bg_pattern_purple_small.png');
      }
    }
  }

  @media (min-width: 767px) {
    .button {
      font-size: 48px;
      font-size: 4.8rem;
    }
    &--footer-view > .wrapper {
      display: flex;
      justify-content: center;

      .button {
        width: 30%;
      }
    }
  }
}

.no-touchevents {
  .button--404:hover {
    text-decoration: none !important;
    .button_inner {
      background-color: $color_pink;
      background-image: none;
    }
  }
  .button {
    &:hover {
      .button_icon {
        @include translatecentery(rotate(-10deg));
      }
      .button_inner {
        background-color: $color_pink;
        background-image: none;
      }
    }
    &__games:hover,
    &.games_holder:hover {
      .button_inner {
        background-color: $color_pink;
        background-image: none !important;
      }
    }
    &__comics:hover,
    &__videos:hover,
    &.videos_holder:hover,
    &.comics_holder:hover {
      .button_inner {
        background-color: $color_blue;
        background-image: none !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .button {
    @include fontsize(math.div(48, 2));
    line-height: 1;
    margin-bottom: math.div($sectionMargin, 2);

    // temp override the locale button font size
    // So old design can keep the old styling
    font-size: calc(48px / 2);
    font-size: calc(4.8rem / 2);

    .button_inner {
      padding-top: 10px;
      padding-right: 50px;
      padding-bottom: 10px;
    }
    .button_icon {
      height: math.div($iconHeight, 2);
    }
  }
  [dir='rtl'] {
    .button {
      .button_inner {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
  .buttons {
    .button {
      width: 100%;
      &:nth-child(odd) {
        margin-right: 0;
      }
      &:nth-child(even) {
        margin-left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

[dir='rtl'] {
  .buttons .button {
    line-height: 1.5;
    @media (min-width: 767px) {
      font-size: calc(24px);
      font-size: calc(2.4rem);
    }
  }
}

.button--404 {
  position: relative;
  display: inline-block;
  @include fontsize(40);
  line-height: 1;
  @include fontb();
  text-transform: none;
  text-decoration: none;
  cursor: pointer;

  background-color: white;
  border: {
    style: solid;
    color: white;
    top-width: 3px;
    right-width: 10px;
    bottom-width: 10px;
    left-width: 3px;

    top-left-radius: 23px;
    top-right-radius: 32px;
    bottom-right-radius: 30px;
    bottom-left-radius: 32px;
  }
  margin: {
    right: 1%;
    left: 1%;
  }

  .button_inner {
    position: relative;
    display: block;
    padding: 10px;
    padding-right: 20px;
    color: #ffffff;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    background-color: $color_purple;
    @include backgroundimage('bg_pattern_purple_small.png');

    background: {
      position: top right;
      repeat: no-repeat;
      size: cover;
    }
    border-radius: 20px;

    &.multi_line {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }
  }
}
