/*
 * Base fonts : lazy loaded
*/
@font-face {
  font-family: 'iconcomics';
  font-display: fallback;
  src: url('#{$font_path}iconcomics/iconcomics.woff?noqdlo') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GarageGothicBlack';
  font-display: fallback;
  src: url('#{$font_path}/garagegothic/garagegothic-black.woff2') format('woff2'), url('#{$font_path}/garagegothic/garagegothic-black.woff') format('woff');
}
