//colors
$black: rgb(0, 0, 0);
$black_50: rgba(0, 0, 0, 0.5);

/* Carousel Widget */
.carousel_widget {
  position: relative;
  overflow: hidden;
  @include noselect();
  @include perspective(1000px);
  /* RIBBON OVERFLOW FIX BAD HACK!*/
  width: -webkit-calc(100% + 15px);
  width: calc(100% + 15px);
  left: -15px;
  padding-left: 15px;
  /* RIBBON OVERFLOW FIX BAD HACK!*/
}

.carousel_widget .carousel_area {
  position: relative;
  overflow: hidden;
}

.carousel_widget .carousel_wrapper {
  -webkit-transition: -webkit-transform 0.4s, left 0.4s;
  transition: transform 0.4s, left 0.4s;
  font-size: 0;
}

.carousel_widget .carousel_wrapper.no_transition {
  transition: none;
  -webkit-transition: none;
}

.carousel_item {
  // display: inline-block;
  vertical-align: top;
  position: relative;
}
