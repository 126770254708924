@include keyframes(bounceAnim) {
	0% {
		@include transform(scale(1,1));
	}

	20% {
		@include transform(scale(0.95,1.03));
	}

	40% {
		@include transform(scale(1.03,0.95));
	}

	60% {
		@include transform(scale(0.96,1.03));
	}

	80% {
		@include transform(scale(1.03,0.97));
	}

	100% {
		@include transform(scale(1,1));
	}
}

@include keyframes(bounceAnimLoopCenter) {

	0% {
		@include translatecenter(scale(1) rotate(0deg));
	}

	18% {
		@include translatecenter(scale(1.2) rotate(-25deg));
	}

	36% {
		@include translatecenter(scale(1) rotate(0deg));
	}
}