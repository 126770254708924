.caption {
  text-transform: none;
  @include fontsize(18);
  padding: math.div($padding, 2) 0;

  @media (min-width: 767px) {
    @include fontsize(24);
    padding: $padding 0;
  }
  h1 {
    color: $color_purple;
    @include fontsize(24);
    @include fontb();
    padding-bottom: math.div($padding, 2);

    @media (min-width: 767px) {
      @include fontsize(48);
    }
  }

  &--center {
    text-align: center;
  }

  .like_views_container {
    // background-color: $color_purple;
    border-radius: 30px;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.b_yellow,
.b_blue {
  .caption {
    a {
      color: $color_pink;
    }
  }
}

.b_pink,
.b_purple {
  .caption {
    a {
      color: $color_blue;
    }
  }
}

.no-touchevents {
  .caption {
    a:hover {
      text-decoration: underline;
    }
  }
}
