$image_path: '../images/';
$image_path_critical: '../images/critical/';
$font_path: '../fonts/';
$font_path_critical: '../fonts/critical/';

$color_facebook: #3b5998;
$color_twitter: #00b0ed;
$color_linkedin: #0065a0;

$bounce: cubic-bezier(0.47, 2.02, 0.31, -0.36);
$mildbounce: cubic-bezier(0.47, 3.32, 0.31, -0.36);
$superbounce: cubic-bezier(0.47, 5.02, 0.31, -0.36);

$padding: 20px;
$sectionMargin: 30px;
$iconHeight: 56px;

$labelColor: rgba(0, 0, 0, 0.3);

$color_blue: #009cde;
$color_yellow: #ffea00;
$color_purple: #8a308a;
$color_pink: #ed0080;
$color_green: #02c764;

$color_pink_offset: #d2006e;
$color_green_offset: #02c764;
