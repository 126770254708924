.view_counter {
  display: inline-block;
  vertical-align: top;
  @include noselect();
  @include fontsize(26);
  line-height: 1;
  // direction: ltr;
  .icon_container,
  .count {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .icon_container {
    width: 48px;
    height: 48px;
    padding: 0px 10px;

    @media screen and (min-width: 768px) and (max-width: 945px) {
      height: 44px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 10%;
      width: 80%;
      max-height: 100%;
      @include translatecentery();
    }
    //&.small {
    //	width: 44px;
    //	}
  }
  .count {
    color: #ffffff;
    padding: 12px;
    width: 70px;
    text-align: center;
    &.small {
      width: 40px;
    }
  }
}

@media (max-width: 767px) {
  .view_counter {
    display: block;
    &.nowrap {
      display: inline-block;
    }
  }
}
