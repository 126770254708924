/* POPUPS */
.popup {
  top: 0;
  left: 3%;
  width: 94%;
  height: auto;
  background-color: #ffffff;
  color: #000000;
  padding: 40px;
  display: none;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: absolute;
  z-index: 999;
  margin-top: 1%;
  overflow: hidden;
  @include fontsize(32);
  .copy {
    margin-top: 28px;
    word-wrap: break-word;
    ol,
    ul {
      margin-left: 20px;
    }
    ol,
    ul,
    p {
      margin-bottom: 20px;
      word-wrap: break-word;
    }
    h2,
    h1 {
      @include fontsize(38);
      margin-bottom: 30px;
    }
  }

  .close_btn {
    height: 60px;
    line-height: 60px;
    width: 60px;
    text-align: center;
    background-color: #000000;
    color: #ffffff;
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    font-size: 40px;
    font-weight: bold;
  }

  .btn {
    height: 60px;
    line-height: 40px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
    cursor: pointer;
    font-size: 40px;
    font-weight: bold;
    background-color: #000000;
    color: #ffffff;
  }

  a,
  a:visited,
  a:active,
  a:focus {
    color: #ec008c;
  }

  &.active {
    display: block;
  }

  &.popup_content_scrolling {
    height: 100%;
    position: fixed;
    overflow: auto;
  }
}
