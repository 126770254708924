@media only screen and (max-width: 1168px) {
  .gameCategorys .wrapper {
    padding: 20px 0;
  }

  .gameCategorys .swiper-wrapper {
    padding: 0 5px;
  }

  .gameCategorys .category_list {
    -webkit-mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 2%, rgb(0, 0, 0) 98%, rgba(0, 0, 0, 0) 100%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1168px) {
  .gameCategorys .swiper-wrapper {
    padding: 0 15px;
  }
}

.category_list {
  .swiper-container {
    .swiper-slide {
      .category_item {
        position: relative;
        //display: block;
        display: inline-block;
        @include fontsize(25);
        line-height: 1;
        @include fontb();
        text-transform: none;
        text-decoration: none;
        cursor: pointer;
        width: 75%;
        .category_item_inner {
          position: relative;
          display: block;
          //padding: 10px;
          //padding-left: 20px;
          //padding-right: 60px;

          color: $color_blue;
          background-color: #fff;

          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          border-radius: 10px;
          padding: 10px 10px;
        }

        &:hover {
          .category_item_inner {
            background-color: $color_pink;
            background-image: none;
          }
        }

        &.category_item.selected {
          .category_item_inner {
            color: #fff;
            background-color: $color_blue;
          }
        }
      }
    }
  }

  .swiper-container.swiper-container-horizontal {
    width: 100%;
    .swiper-slide {
      .category_item {
      }
    }
  }

  .swiper-container.swiper-container-vertical {
    width: 15%;
    .swiper-slide {
      .category_item {
        //height: 50px;
      }
    }
  }
}
